import NumberInput from "../components/UI/NumberInput";
import { useState, useEffect } from "react";
import React from "react";
import SimpleInputForm from "../components/UI/SimpleInputForm";

import { fetchArticle, fetchLatestArticle, mintArticleNFT } from "../myModules/api";

import { Decimal } from "decimal.js";
import MintDialogue from "../components/UI/MintDialogue";
import { Address, isAddress } from "viem";
import Loading from "../components/UI/Loading";
import { useLocation } from "react-router-dom";
import { Article } from "../types/apiTypes";
import { useParams } from 'react-router-dom';
import { convertMintPriceToDisplay, isPositiveInteger } from "../myModules/utils";
import NotFound from "../components/NotFound";

const isDateInRange = (currentDate:bigint, dateStart:bigint, dateEnd:bigint) => {
  return currentDate >= dateStart && currentDate <= dateEnd;
};

const Mint: React.FC = () => {
  const [tokensAmount, setTokensAmount] = useState<{ [key: string]: number }>({
    amount: 1,
  });
  const [address, setAddress] = useState<`0x${string}` | "">("");
  const handleChange = (e) => {
    setAddress(e.target.value);
  };

  const [article, setArticle] = useState<Article | null>(null);
  const [isArticleAvailable, setIsArticleAvailable] = useState(true);
  const [notFoundErr, setNotFoundErr] = useState(false);

  const { id } = useParams()
  const [articleId, setarticleId] = useState(id)

  if (typeof id === 'undefined') {
    setNotFoundErr(true)
    console.log("error, articleId is undefined this page should 400")
  } else if (!isPositiveInteger(id) && id !== 'latest') {
    setNotFoundErr(true)
    console.log("error, articleId is must be a positive integer or latest, this page should 400")
  }
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const affiliateParam: string | null = params.get("affiliate");
  let affiliateAddress:Address
  if (affiliateParam !== null && isAddress(affiliateParam)) {
    affiliateAddress = affiliateParam
  }

  useEffect(() => {
    setArticle(null)
    const fetchData = async () => {
      setarticleId(id)
      try {
        let article:Article|null
        if (id === "latest") {
          article = await fetchLatestArticle()
          setarticleId(article.articleId.toString())
        } else if (typeof id !== 'undefined' && isPositiveInteger(id)) {
          const aid = BigInt(id.toString())
          article = await fetchArticle(aid)
        } else {
          console.log("malformed articleId should 400something")
          throw new Error("bad article Id in URL or undefined")
        }
        if (article === null) {
          setNotFoundErr(true)
        } else {
          const currentDate = BigInt(new Date().getTime()) / 1000n;
          console.log("currentDate:", currentDate, "start:", article.mintStart, "end:", article.mintEnd)
          const isInRange = isDateInRange(
            BigInt(currentDate),
            article.mintStart,
            article.mintEnd
          );
          if (!isInRange) {
            setIsArticleAvailable(false);
          } else {
            setIsArticleAvailable(true);
          }
  
          setArticle(article);
        }

      } catch (error) {
        setNotFoundErr(true)
        console.error("Error fetching article previews:", error);
      }
    };

    fetchData();
  }, [location.search, id]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [responseState, setResponseState] = useState();
  const [dialogState, setDialogState] = useState(false);
  const toggleDialog = () => {
    setDialogState((prev) => !prev);
  };

  const handleSubmit = async () => {
    toggleDialog();
    setIsLoading(true);
    try {
      setTimeout(async () => {
        if (address) {
          if (typeof articleId === 'undefined') {
            throw new Error("articleId is undefined")
          }
          const response = await mintArticleNFT(
            BigInt(articleId),
            BigInt(tokensAmount.amount),
            address,
            affiliateAddress
          );
          //@ts-ignore
          setResponseState(response);
          if (response.isError) {
            setIsError(true);
          }
          setIsLoading(false);
        }
      }, 1000);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
  };

  if (notFoundErr) {
    return <NotFound/>
  }
  if (!article) {
    return (
      <div className="flex-c min-h-[300px]">
        <Loading />
      </div>
    );
  }
  return (
    <main className="py-8">
      <MintDialogue
        state={dialogState}
        toggle={toggleDialog}
        isLoading={isLoading}
        isError={isError}
        setIsError={setIsError}
        response={responseState}
        setResponseState={setResponseState}
      />

      <article className=" flex flex-col gap-8 ">
        <div className="my-8 flex flex-col lg:flex-row gap-8 xl:gap-20">
          <div className="flex-1 flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              {/* @ts-ignore */}
              <img src={article.articleNFTImageURL} alt="nft preview" className="rounded-lg" />
            </div>
          </div>
          <div className="flex-1  lg:pt-12">
            <div className=" max-w-screen-xs mx-auto flex flex-col gap-8">
              <div className="flex-c">
                <NumberInput
                  name={"amount"}
                  tokensAmount={tokensAmount}
                  setTokensAmount={setTokensAmount}
                  width={32}
                  classes={"!w-full md:w-32"}
                />
              </div>
              <div className="flex flex-col gap-4 text-lg">
                <div className="flex justify-between gap-2 flex-wrap">
                  <span className="font-medium">Price per item:</span>
                  <span className="apply-gr-text font-semibold text-xl">
                    {convertMintPriceToDisplay(article.mintPrice)} ETH
                  </span>
                </div>
                <div className="flex justify-between gap-2 flex-wrap">
                  <span className="font-medium">Total:</span>
                  <span className="apply-gr-text font-semibold text-xl">
                    {new Decimal(convertMintPriceToDisplay(article.mintPrice))
                      .times(tokensAmount.amount)
                      .toNumber()}{" "}
                    ETH
                  </span>
                </div>
                <div className="flex justify-between gap-2 flex-wrap mt-6">
                  {/* <span className="font-medium">Recipient Address:</span> */}
                  <div className="flex gap-2 items-center w-full">
                    <SimpleInputForm
                      name="address"
                      error={!isAddress(address) && String(address).length > 0}
                      label={"Recipient Address"}
                      placeholder={"Enter The Recipient Address"}
                      value={address}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-c">
                <button
                  onClick={() => handleSubmit()}
                  disabled={
                    tokensAmount.amount <= 0 ||
                    address.length <= 0 ||
                    !isAddress(address) ||
                    !isArticleAvailable
                  }
                  className={`btn btn-gr disabled:opacity-70 capitalize ${
                    !isArticleAvailable && "grayscale"
                  }`}>
                  {isArticleAvailable ? "mint" : "article unavailable"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </main>
  );
};

export default Mint;
